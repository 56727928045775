<template>
  <div class="hero-case-study">
    <LetsChat></LetsChat>
    <div class="grid-container">
      <div class="hero__content logo-vonino">
        <img src="@/assets/casestudy/vonino-logo.svg" alt="Vonino">
        <span class="sep"></span>
        <p>VONINO Corporation is an American holding company, responsible for global marketing of digital products, which provides electronic products from consumer, commercial, industrial & lifestyle
          applications.

          It has developed into one of the fastest growing OEM/ODM manufacturers, engaged in the production of Tablet PCs, smart phones, GPS Navigation, MP3 players, USB disks and computer
          accessories.</p>
      </div>
    </div>

    <div class="laptop-img vonino-laptop">
      <div class="grid-container">
        <img src="@/assets/casestudy/vonino-main-img.png" alt="Vonino">
      </div>
    </div>

    <div class="container-about vonino-about">
      <div class="grid-container">
        <img src="@/assets/casestudy/vonino-diamond.svg" alt="Vonino">
        <h3>About the project</h3>
        <p>Is a technology manufacturer of affordable smartphones, tablets, laptops and IoT gadgets.<br/>
          With a great focus in innovation, they aim high and they bring new age technology to the market at a very balanced price.<br/>
          Our partnership includes of handling all of their e-commerce platforms, some campaigns, video animations and also cool apps dedicated to their IoT devices and their customer support
          platform.</p>
      </div>
    </div>

    <div class="container-typography">
      <div class="grid-container">
        <div class="container-services__content">
          <h3>What we do</h3>

          <div class="wwd-left">
            <h4>Managed services</h4>
            <img src="@/assets/casestudy/web-app.svg" width="60px" height="60px">
            <h4>Support</h4>
            <img src="@/assets/casestudy/maintenance-icon.svg" width="60px" height="60px">
            <h4>SEO</h4>
            <img src="@/assets/casestudy/consultancy-icon.svg" width="60px" height="60px">
          </div>

          <span class="sep vertical"></span>

          <div class="wwd-right">
            <img src="@/assets/casestudy/managed-services.svg" width="60px" height="60px">
            <h4>Custom Web App's</h4>
            <img src="@/assets/casestudy/support-icon.svg" width="60px" height="60px">
            <h4>Maintenance</h4>
            <img src="@/assets/casestudy/seo-icon.svg" width="60px" height="60px">
            <h4>Consultancy</h4>
          </div>
        </div>
      </div>
    </div>

    <testimonial backgroundTsColor="#08C" tsTitle="#fafafa">
      <div class="testimonial-text">
        <p class="testimonial-title-text">Florentin-Catalin Dumitru, Marketing Manager</p>
        <p class="testimonial-text__content">"The Scriptics Team is every project manager’s dream: it covers the whole range of developers, the work is delivered on time, everything is made to last.
          Literally an A+ task-force!"
        </p>
      </div>
    </testimonial>


    <div class="container-nav-projects">
      <div class="grid-container">
        <div class="container-nav-projects__content">
          <h3>Related projects</h3>

          <div class="container-nav-projects-left">
            <router-link :to="{ name:'Pica8'}" class="left">
              <img src="@/assets/casestudy/btn-pica8.jpg" alt="Pica8">
            </router-link>
          </div>

          <div class="container-nav-projects-right">
            <router-link :to="{ name: 'Summerwood'}" class="right">
              <img src="@/assets/casestudy/btn-sw.jpg" alt="Summerwood">
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <ContactUs color="#08C"></ContactUs>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "../../components/Home/Footer";
import ContactUs from "../../components/ContactUs";
import Testimonial from "../../components/Testimonial";
import LetsChat from "../../components/LetsChat";

export default {
  name: "Vonino",
  components: {LetsChat, Testimonial, ContactUs, Footer}
}
</script>

<style lang="scss">

.logo-vonino img {
  max-width: 320px;
}
.vonino-laptop {
  background-color: #08C;
}

.container-nav-projects__content h3 {
  display: block;
  background: rgb(58, 58, 58);
  background: linear-gradient(125deg, rgba(58, 58, 58, 1) 50%, #08C 60%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

</style>